<template>
  <div v-if="recipe" id="modal" class="modal" @click="closeModal">
    <div class="recipe__modal">
      <div class="recipe__modal-inner" @click.stop="">
        <button class="knives__modal-close" @click="closeModal">
          <img src="@/assets/img/icons/x.svg" alt="" />
        </button>
        <div class="knives__modal-line"></div>

        <div class="recipe__modal-main">
          <div class="knives__modal-arrows">
            <button
              :disabled="recipeIndex === 0"
              class="knives__modal-btn knives__modal-prev"
              @click="prevKnife()"
            >
              <img src="@/assets/img/knives/chevron-prev.svg" alt="" />
            </button>
            <button
              :disabled="recipeIndex === recipes.length - 1"
              @click="nextKnife()"
              class="knives__modal-btn knives__modal-next"
            >
              <img src="@/assets/img/knives/chevron-next.svg" alt="" />
            </button>
          </div>
          <div class="recipe__modal-img">
            <div v-if="recipe.youtube" v-html="recipe.youtube"></div>
            <!-- <img :src="recipe.image" alt="" /> -->
          </div>
          <div class="recipe__modal-info">
            <button class="recipe__modal-print">
              <img src="@/assets/img/knives/print.svg" alt="" />
              {{ $t("knives.print") }}
            </button>
            <div class="recipe__modal-box">
              <div class="recipe__modal-title">{{ $t(recipe.title) }}</div>
              <button
                v-if="$isMobile()"
                class="recipe__modal-share"
                @click="share"
              >
                <img src="@/assets/img/knives/share.svg" alt="" />
              </button>
            </div>
            <div class="recipe__modal-time">
              {{ $t("knives.time") }} 30 минут
            </div>
            <div class="recipe__modal-complexity">
              <p>{{ $t("knives.complexity") }}:</p>
              <div class="recipe__modal-rating">
                <svg
                  v-for="(item, index) in 5"
                  :key="index"
                  :class="{ active: item <= recipe.complexity }"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.8133 13.6H4.10216C3.54987 13.6 3.10216 13.1523 3.10216 12.6V11.8787C3.10216 11.4641 2.83936 11.0976 2.4672 10.9147C-1.68964 8.87167 0.0659284 4.48595 2.90085 3.71744C3.38329 3.58666 3.80039 3.22326 3.99028 2.76088C5.26959 -0.354105 10.9432 -1.5274 13.0302 2.9736C13.2018 3.34356 13.5568 3.60506 13.9596 3.66857C18.4356 4.37435 17.2849 9.85171 14.8729 10.9905C14.3425 11.2409 13.8133 11.6944 13.8133 12.2808V12.6C13.8133 13.1523 13.3655 13.6 12.8133 13.6Z"
                    fill="#F0C9D9"
                  />
                  <path
                    d="M12.8133 14.7333H4.10216C3.54987 14.7333 3.10216 15.181 3.10216 15.7333V16C3.10216 16.5523 3.54987 17 4.10216 17H12.8133C13.3655 17 13.8133 16.5523 13.8133 16V15.7333C13.8133 15.181 13.3655 14.7333 12.8133 14.7333Z"
                    fill="#F0C9D9"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="recipe__modal-bottom">
          <div class="recipe__instruction">
            <h6 class="recipe__modal-bottom--title">
              {{ $t("knives.instruction") }}:
            </h6>
            <ul>
              <li v-for="(item, index) in $t(recipe.instruction)" :key="index">
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="recipe__ingredients">
            <h6 class="recipe__modal-bottom--title">
              {{ $t("knives.ingredients") }}:
            </h6>
            <div class="recipe__ingredients-list">
              <p
                v-for="(item, index) in $t(recipe.ingredients)"
                :key="index"
                :class="{ 'recipe__ingredients-title': item.label === null }"
              >
                <span v-if="item.title">{{ item.title }}</span>
                <span v-if="item.label">{{ item.label }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppRecipeModal",
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      recipeIndex: null,
    };
  },
  computed: {
    ...mapState("knivesModule", ["knifes", "recipes"]),
    recipe() {
      return this.recipes[this.recipeIndex];
    },
  },
  mounted() {
    this.recipeIndex = this.index;
    if (this.$isMobile()) {
      setTimeout(() => {
        document.querySelector("#modal").scrollTop = 100;
      }, "100");
      setTimeout(() => {
        this.addClassScrollBtn();
      }, "300");
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    nextKnife() {
      this.recipeIndex++;
    },
    prevKnife() {
      this.recipeIndex--;
    },
    share() {
      navigator.share({
        title: "hello", // Заголовок
        text: "magnum", // Текст
        url: "https://magnum-front.a-lux.dev/ru/?city_id=2", // ссылка
      });
    },
    addClassScrollBtn() {
      const modal = document.querySelector("#modal");
      const onscroll = (e) => {
        e.stopPropagation();
        let scroll = modal.pageYOffset || modal.scrollTop;
        if (scroll === 0) {
          setTimeout(() => {
            this.$emit("close");
          }, "100");
        } else {
          return;
        }
      };
      modal.addEventListener("scroll", onscroll);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  scroll-behavior: smooth;
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar {
    height: 0;
    opacity: 0;
  }

  &::-webkit-scrollbar-button {
    height: 0;
    opacity: 0;
  }
}
</style>
